document.addEventListener('turbo:load', () => {
  const paypalButtonContainer = document.querySelector('#paypal-button-container');
  if (!paypalButtonContainer) { return; }
  const amount = paypalButtonContainer.dataset.amount;
  const objectId = paypalButtonContainer.dataset.id;
  const url = paypalButtonContainer.dataset.url;
  const spinner = document.querySelector('.spinner-border');

  paypal.Buttons({
    createOrder: function(data, actions) {
      return actions.order.create({
        purchase_units: [{
          amount: {
            value: amount
          }
        }],
        application_context: {
          brand_name: "MMA Registry",
          shipping_preference: 'NO_SHIPPING'
        }
      });
    },
    onApprove: function(data, actions) {
      paypalButtonContainer.style.display = 'none';
      spinner.style.display = 'block';

      const csrfToken = document.getElementsByName("csrf-token")[0].content;
      return actions.order.capture().then(function(details) {
        global.$.ajax(url,
          {
            type: 'post',
            dataType: 'json',
            data: {
              object_id: objectId,
              payment: {
                paypal_order_id: details.id,
                paypal_capture_id: details.purchase_units[0].payments.captures[0].id,
                amount: details.purchase_units[0].amount.value,
                payer_email: details.payer.email_address,
                payer_name: details.payer.name.given_name + ' ' + details.payer.name.surname
              }
            },
            timeout: 30000,
            beforeSend: function(xhr) {
              xhr.setRequestHeader("X-CSRF-Token", csrfToken);
            },
            complete: function() {
              spinner.style.display = 'none';
            },
            success: function(data) {
              document.querySelector('.payment-successful').style.display = 'block';
            },
            error: function(jqXHR, textStatus, errorThrown) {
              const errorBox = document.querySelector('.server-error');
              message = jqXHR.responseJSON ? jqXHR.responseJSON.message : jqXHR.statusText;
              errorBox.appendChild(document.createTextNode('Details: ' + message));
              errorBox.style.display = 'block';
            }
          }
        )
      });
    },
    onError: function (err) {
      console.log(err);
      alert("An unexpected error has occurred. Please try again later.");
    }
  }).render('#paypal-button-container');
})
