import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    roster: String,
  }

  weightDivisionSelect({target}) {
    fetch(`${this.rosterValue}?weight_division_id=${target.value}`)
      .then((res) => res.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);
        document.getElementById("champion-fighters-input-row").replaceWith(fragment);
    });
  }
}
