import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];
  static classes = ['d-none'];

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none';
  }

  toggle(event) {
    if (event.currentTarget.checked) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.fieldTargets.forEach(item => {
      item.classList.remove(this.class);
      item.querySelectorAll('input,select').forEach(input => input.disabled = false);
    });
  }

  hide() {
    this.fieldTargets.forEach(item => {
      item.classList.add(this.class);
      item.querySelectorAll('input,select').forEach(input => input.disabled = true);
    });
  }
}
