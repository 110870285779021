document.addEventListener('turbo:load', () => {
  const form = document.querySelector('form.edit_fight');
  if (!form) { return; }

  // hybrid sport
  const sportSelector = document.querySelector('form.edit_fight #fight_sport_id');
  if (sportSelector) {
    handleSportChange(sportSelector);
    sportSelector.addEventListener('change', handleSportChange);
  }

  handleTitleFightChange(form);
  handleMinMaxWeight();
  handleCatchweight();

  toggleTitleFightType();
  toggleSanctioningBodyDropdown();

  form.querySelectorAll('select#fight_weight_division_id')
    .forEach(input => 
      input.addEventListener('change', () => {
        handleMinMaxWeight();
        handleCatchweight();
      })
    );
});

const handleSportChange = () => {
  showFieldsPerSport();
  handleCatchweight();
  toggleTitleFightType();
  toggleSanctioningBodyDropdown();
};

const handleCatchweight = () => {
  if (isSport("Professional Wrestling")) { return; }

  const form = document.querySelector('form.edit_fight');
  const fieldsBlock = isSport("MMA") ? form.querySelector('.mma-fight-details') : form.querySelector('.non-mma-fight-details');
  const catchweightContainer = fieldsBlock.querySelector('.fight_catchweight');

  if (!fieldsBlock || !catchweightContainer) {
    return;
  }

  const wdSelect = fieldsBlock.querySelector('select#fight_weight_division_id');
  const selectedOption = wdSelect.options[wdSelect.selectedIndex];

  catchweightContainer.classList.toggle('d-none', selectedOption.dataset.catchweight !== 'true');
};

const handleMinMaxWeight = () => {
  if (isSport("MMA") || isSport("Professional Wrestling")) {
    return;
  }

  const form = document.querySelector('form.edit_fight');
  const fieldsBlock = form.querySelector('.non-mma-fight-details');
  const minMaxWeightBlock = fieldsBlock.querySelector('.min-max-weight-block');

  if (!fieldsBlock || !minMaxWeightBlock) {
    return;
  }

  const wdSelect = fieldsBlock.querySelector('select#fight_weight_division_id');
  const selectedOption = wdSelect.options[wdSelect.selectedIndex];

  minMaxWeightBlock.classList.toggle('d-none', selectedOption.value.length === 0);
};

const handleTitleFightChange = (form) => {
  const titleFightRadioButtons = form.querySelectorAll("input[type=radio][name='fight\[title\]']");

  titleFightRadioButtons.forEach(button =>
    button.addEventListener('change', () => {
      toggleTitleFightType();
      toggleSanctioningBodyDropdown();
    })
  );
};

const toggleSanctioningBodyDropdown = () => {
  const sanctioningBodyBlock = document.querySelector('form.edit_fight .sanctioning_body_ids');
  if (!sanctioningBodyBlock) { return; }

  sanctioningBodyBlock.classList.toggle('d-none', !isSanctioningBodyVisible());
  sanctioningBodyBlock.querySelectorAll('select').forEach(dropdown => dropdown.disabled = !isSanctioningBodyVisible());
};

const toggleTitleFightType = () => {
  const titleFightDropdown = document.querySelector('form.edit_fight .fight_title_fight_type');
  if (!titleFightDropdown) {
    return;
  }

  const titleFightSelect = titleFightDropdown.querySelector('select');

  titleFightSelect.disabled = !isTitleFightTypeVisible();
  titleFightDropdown.classList.toggle('d-none', !isTitleFightTypeVisible());
};

const showFieldsPerSport = () => {
  const form = document.querySelector('form.edit_fight');

  if (isSport("MMA")) {
    showFormFieldsBlock(form, '.mma-fight-details');

    hideFormFieldsBlock(form, '.wrestling-fight-details');
    hideFormFieldsBlock(form, '.non-mma-fight-details');
    hideFormFieldsBlock(form, '.slap-fighting-fight-details');
    showFormFieldsBlock(form, '[data-pro-wrestling-hide=true]');
    hideFormFieldsBlock(form, '.fck-fight-details');
  } else if (isSport("Professional Wrestling")) {
    showFormFieldsBlock(form, '.wrestling-fight-details');

    hideFormFieldsBlock(form, '.mma-fight-details');
    hideFormFieldsBlock(form, '.non-mma-fight-details');
    hideFormFieldsBlock(form, '.slap-fighting-fight-details');
    hideFormFieldsBlock(form, '[data-pro-wrestling-hide=true]');
    hideFormFieldsBlock(form, '.fck-fight-details');
  } else if (isSport('Full Contact Karate')) {
    showFormFieldsBlock(form, '.fck-fight-details');

    hideFormFieldsBlock(form, '.mma-fight-details');
    hideFormFieldsBlock(form, '.non-mma-fight-details');
    hideFormFieldsBlock(form, '.slap-fighting-fight-details');
    hideFormFieldsBlock(form, '.wrestling-fight-details');
    showFormFieldsBlock(form, '[data-pro-wrestling-hide=true]');
  } else if (isSport('Slap Fighting')) {
    showFormFieldsBlock(form, '.slap-fighting-fight-details');

    hideFormFieldsBlock(form, '.mma-fight-details');
    hideFormFieldsBlock(form, '.non-mma-fight-details');
    hideFormFieldsBlock(form, '.fck-fight-details');
    hideFormFieldsBlock(form, '.wrestling-fight-details');
  } else {
    showFormFieldsBlock(form, '.non-mma-fight-details');

    hideFormFieldsBlock(form, '.fck-fight-details');
    hideFormFieldsBlock(form, '.wrestling-fight-details');
    hideFormFieldsBlock(form, '.mma-fight-details');
    showFormFieldsBlock(form, '[data-pro-wrestling-hide=true]');
  }
};

const showFormFieldsBlock = (form, selector) => {
  const fieldsBlock = form.querySelectorAll(selector);
  if (fieldsBlock.length === 0) { return; }

  fieldsBlock.forEach(block => {
    block.classList.remove('d-none');

    const inputs = block.querySelectorAll('input,select');
    inputs.forEach(input => input.disabled = false);
  });
};

const hideFormFieldsBlock = (form, selector) => {
  const fieldsBlock = form.querySelectorAll(selector);
  if (fieldsBlock.length === 0) { return; }

  fieldsBlock.forEach(block => {
    block.classList.add('d-none');

    const inputs = block.querySelectorAll('input,select');
    inputs.forEach(input => input.disabled = true);
  });
};

const isSport = (sportName) => {
  const sportSelector = document.querySelector('form.edit_fight #fight_sport_id');
  if (!sportSelector) { return; }

  return sportSelector.options[sportSelector.selectedIndex].text === sportName;
};

const isTitleFight = () => {
  const checkedRadio = document.querySelector("form.edit_fight input[type=radio][name='fight\[title\]']:checked");
  return checkedRadio && checkedRadio.id === 'fight_title_true';
};

const isSanctioningBodyVisible = () => isTitleFight() && (isSport("Boxing") || isSport("Kickboxing") || isSport("Muay Thai"));
const isTitleFightTypeVisible = () => isTitleFight() && (isSport("Boxing") || isSport("Bare Knuckle"));
