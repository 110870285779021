import Sortable from 'sortablejs';

document.addEventListener('turbo:load', () => {
  const sections = document.querySelectorAll('.sortable-fights');
  if (sections.length === 0) { return; }
  const csrfToken = document.getElementsByName("csrf-token")[0].content;

  sections.forEach((section) => {
    const sectionID = section.dataset.sectionId;

    Sortable.create(section, {
      dataIdAttr: 'data-id',
      group: 'sections',
      onSort: function () {
        const sortedFightIds = this.toArray();
        fetch(window.location.pathname,
          {
            method: 'PATCH',
            headers: {
              "X-CSRF-Token": csrfToken, 
              "Content-Type": "application/json", 
            },
            body: JSON.stringify({ event_section_id: sectionID, fight_ids: sortedFightIds })
          }
        ).catch((e) => {
          console.log(e);
          alert('An error occured while saving the fight order. Please refresh the page and try again.')
          })
        }
      })
  });
});
