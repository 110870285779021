document.addEventListener('turbo:load', () => {
  const button = document.querySelector('a.print-card');
  if (!button) { return; }
  const url = button.href;

  button.addEventListener('click', (event) => {
    event.preventDefault();
    const pdfWindow = window.open(url);
    pdfWindow.focus();
    setTimeout(function(){
      pdfWindow.print();
    }, 2000);
  });
})
