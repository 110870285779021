document.addEventListener('turbo:load', () => {
  const userSelect = document.querySelector('form.event select#event_user_id');
  if (!userSelect) { return; }
  const promotionSelect = document.querySelector('form.event select#event_promotion_id');

  promotionSelect.addEventListener('change', (event) => {
    populateUserSelect(global.$(userSelect), event.target.value);
  });
})

const populateUserSelect = (userSelect, promotionId) => {
  userSelect.empty();
  if (!promotionId) { return; }

  global.$.getJSON('/users', { promotion_id: promotionId }, (data) => {
    userSelect.append(`<option></option>`);
    global.$.each(data, function(index, item) {
      userSelect.append(`<option value="${item.id}">${item.full_name}</option>`);
    });
  });
};
