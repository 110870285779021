document.addEventListener('turbo:load', () => {
  const form = document.querySelector('form.organization');
  if (!form) { return; }
  const categorySelect = form.querySelector('select#organization_category');

  disableOrganizationSelects(categorySelect.value);

  categorySelect.addEventListener('change', (event) => {
    disableOrganizationSelects(event.target.value);
  });
})

const disableOrganizationSelects = (category) => {
  const acContainer    = document.querySelector('.commission-only');
  const promoContainer = document.querySelector('.promotion-only');

  if (category == "commission") {
    acContainer.style.display = 'block';
    promoContainer.style.display = 'none';
  }

  if (category == "sanctioning_body") {
    acContainer.style.display = 'none';
    promoContainer.style.display = 'none';
  }

  if (category == "promotion") {
    acContainer.style.display = 'none';
    promoContainer.style.display = 'block';
  }
};
