import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  static targets = ['input']

  currencyMask = {
    mask: '$num',
    blocks: {
      num: {
        // nested masks are available!
        mask: Number,
        thousandsSeparator: ',',
        signed: false,
        scale: 2,
        min: 0,
        max: 99999999,
        radix: '.',
        mapToRadix: ['.']
      }
    }
  };

  connect() {
    const maskedInputs = this.inputTargets.map(input => IMask(input, this.fetchMask(input.dataset.maskedFormFormat)));

    const forms = [...new Set(this.inputTargets.map(input => input.closest('form')))];

    forms.forEach((form) => {
      form.addEventListener('submit', () => {
        maskedInputs.forEach((maskedInput) => {
          const element = maskedInput.el['input'];
          element.value = maskedInput.unmaskedValue;
        })
      })

    });
  }

  fetchMask(maskFormat) {
    if (maskFormat === 'currency') {
      return this.currencyMask;
    }
    return {};
  }
}

