import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['frame'];

  change(event) {
    this.frameTarget.src= event.target.selectedOptions[0].dataset.frameUrl;
    this.frameTarget.reload();
  }
}

