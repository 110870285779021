import { Controller } from "@hotwired/stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from 'uppy-activestorage-upload'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default class extends Controller {
  static targets = ["uploadedFiles", "dashboard", "input"]
  static values = { directUploadUrl: String }

  connect() {
    this.setupUppy()
  }

  setupUppy() {
    this.uppyInstance = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*', 'application/*', 'text/*'],
        minNumberOfFiles: 1,
        maxFileSize: 8388608 // 8 Megabytes
      },
    })

    this.uppyInstance
      .use(ActiveStorageUpload, { directUploadUrl: this.directUploadUrlValue })
      .use(Dashboard, {
        target: this.dashboardTarget,
        inline: true,
        height: 400,
        showLinkToFileUploadResult: true,
        showProgressDetails: true,
        hideProgressAfterFinish: true,
        proudlyDisplayPoweredByUppy: false,
        showRemoveButtonAfterComplete: true,
        note: 'One file up to 8 Megabytes. Supported formats: images, videos, archives or documents'
      })
      .on('upload-success', this.onUploadSuccess.bind(this))
  }

  onUploadSuccess(_, response) {
    const input = this.inputTarget.content.cloneNode(true)

    const field = input.querySelector('input')
    field.value = response.signed_id

    this.uploadedFilesTarget.appendChild(input)
  }
}


