import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-inputs"
export default class extends Controller {
  static targets = [ "amount", "date" ];

  toggle(event) {
    if (event.target.checked) {
      this.amountTarget.readOnly = false;
      this.dateTarget.readOnly = false;

      this.amountTarget.value = this.amountTarget.placeholder;
    } else {
      this.amountTarget.readOnly = true;
      this.amountTarget.value = null;

      this.dateTarget.value = null;
      this.dateTarget.readOnly = true;
    }
  }
}
