document.addEventListener('turbo:load', () => {
  const sportSelector = document.querySelector("form.event #event_sport_id");
  if (!sportSelector) { return; }

  handleSportChange(sportSelector);
  sportSelector.addEventListener('change', e => handleSportChange(e.target));

  const radioButtonsSelector = document.querySelectorAll("form.event input[name='event_organization_type']");
  if (radioButtonsSelector.length === 0) { return; }


  radioButtonsSelector.forEach((radio) => {
    if (radio.checked) {
      showOrganizationSelect(radio.value);
    }

    radio.addEventListener('change', (event) => {
      showOrganizationSelect(event.target.value);
    });
  });
});

const isEditor = () => {
  return document.querySelector('form.event').dataset.editor;
};

const handleSportChange = (sportSelector) => {
  const selectedSport = sportSelector.options[sportSelector.selectedIndex].dataset.slug;

  if (!isEditor()) {
    if (selectedSport === "mma") {
      showRadioButtonsForMMA();
      showUnifiedRulesBlock();
    } else {
      hideRadioButtonsForMMA();
      hideUnifiedRulesBlock();
  
      const onlyAthleticCommission = ["boxing", "bare_knuckle", "pro_wrestling", "cjj", "fck"].includes(selectedSport);
      toggleSanctioningBody(!onlyAthleticCommission);
    }
  }
  
  toggleFightOccursIn(selectedSport !== "boxing");
};


const showRadioButtonsForMMA = () => {
  const radioButtons = document.querySelector('form.event .mma-organization-radio');
  if (radioButtons === null) { return; }

  radioButtons.classList.remove('d-none');

  showOrganizationSelect(selectedOrganizationType());
};

const hideRadioButtonsForMMA = () => {
  const radioButtons = document.querySelector('form.event .mma-organization-radio');
  if (radioButtons === null) { return; }

  radioButtons.classList.add('d-none');

  showSelect('.ac-organization');
  showSelect('.sb-organization');
  disableEmptyOrganizationInpputs();
};

const toggleSanctioningBody = (isActive) => {
  if(isActive) {
    showSelect('.sb-organization');
  } else {
    hideSelect('.sb-organization');
  }
};

const showOrganizationSelect = (organizationType) => {
  if (organizationType == "ac") {
    showSelect('.ac-organization');
    hideSelect('.sb-organization');
    disableEmptyOrganizationInpputs();
  }
  else if (organizationType == "sb") {
    showSelect('.sb-organization');
    hideSelect('.ac-organization');
    disableEmptyOrganizationInpputs();
  }
  else {
    hideSelect('.sb-organization');
    hideSelect('.ac-organization');
    enableEmptyOrganizationInputs();
  }
};

const selectedOrganizationType = () => {
  const selectedRadio = document.querySelectorAll("form.event input[name='event_organization_type']:checked");
  if (selectedRadio.length === 0) {
    return;
  }
  return selectedRadio[0].value;
};

const hideSelect = (selector) => {
  const container = document.querySelector(selector);
  if (!container) return;

  container.style.display = 'none';

  const select = container.querySelector('select');
  select.value = null;
  select.disabled = true;
};

const showSelect = (selector) => {
  const container = document.querySelector(selector);
  if (!container) return;

  container.style.display = 'block';

  const select = container.querySelector('select');
  select.disabled = false;
};

const enableEmptyOrganizationInputs = () => {
  const hiddenAC = document.querySelector('input#event_athletic_commission_id');
  const hiddenSB = document.querySelector('input#event_sanctioning_body_id');

  [hiddenAC, hiddenSB].forEach((input) => {
    input.disabled = false;
    input.value = null;
  });
};

const disableEmptyOrganizationInpputs = () => {
  const hiddenAC = document.querySelector('input#event_athletic_commission_id');
  const hiddenSB = document.querySelector('input#event_sanctioning_body_id');

  [hiddenAC, hiddenSB].forEach((input) => input.disabled = true);
};

const showUnifiedRulesBlock = () => {
  const unifiedRulesBlock = document.querySelector('form.event .event_unified_rules');

  unifiedRulesBlock.classList.remove('d-none');
  unifiedRulesBlock.querySelectorAll('input').forEach(input => input.disabled = false);
};

const hideUnifiedRulesBlock = () => {
  const unifiedRulesBlock = document.querySelector('form.event .event_unified_rules');

  unifiedRulesBlock.classList.add('d-none');
  unifiedRulesBlock.querySelectorAll('input').forEach(input => input.disabled = true);
};

const toggleFightOccursIn = (isVisible) => {
  const fightOccursInBlock = document.querySelector('form.event .event_fights_in');
  fightOccursInBlock.classList.toggle('d-none', !isVisible);
};
