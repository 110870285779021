import { Controller } from "@hotwired/stimulus"
import { loadScript } from "@paypal/paypal-js";

export default class extends Controller {
  static targets = ['container', 'spinner', 'errorMessage', 'successMessage']

  static values = { 'plan': String, 'url': String, 'paypalId': String, 'userEmail': String }

  async connect() {
    const paypal = await this.loadPaypalScript();
    this.renderPaypalButton(paypal);
    this.redirectUrl = '/'
  }

  close() {
    window.location.href = this.redirectUrl;
  }

  renderPaypalButton(paypal) {
    if (!paypal) { return; }

    const self = this;
    const paypalButton = paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'silver',
        layout: 'vertical',
        label: 'subscribe'
      },
      createSubscription: (_, actions) => { 
        return actions.subscription.create({
          plan_id: this.planValue,
          custom_id: self.userEmailValue,
        })
      },
      onApprove: function (data, _actions) {
        self.hidePaypalButtons();
        self.showSpinner();

        self.createSubscription(data);
      },
      onError: function (err) {
        console.log(err);
        self.showErrorMessage();
      }
    })
    paypalButton.render(this.containerTarget); // Renders the PayPal button
  }

  async loadPaypalScript() {
    try {
      return await loadScript({ "client-id": this.paypalIdValue, vault: true, intent: "subscription"});
    } catch (error) {
      console.error("Failed to load the PayPal JS SDK script", error);
      return null;
    }
  }

  async createSubscription(subscriptionData) {
    console.log("Creating subscription");
    const csrfToken = document.getElementsByName("csrf-token")[0].content;

    const data = {
      subscription: {
        subscription_id: subscriptionData.subscriptionID,
        plan_id: this.planValue,
        order_id: subscriptionData.orderID,
        payment_source: subscriptionData.paymentSource
      }
    }

    const response = await fetch(this.urlValue, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
    })
    const responseData = await response.json();
    this.hideSpinner();

    if (response.ok && responseData.status == 'success') {
      this.redirectUrl = responseData.redirect_url;
      this.showSuccessMessage();
    } else {
      this.showErrorMessage();
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('d-none');
    this.spinnerTarget.classList.add('d-flex');
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none');
    this.spinnerTarget.classList.remove('d-flex');
  }

  hidePaypalButtons() {
    this.containerTarget.classList.add('d-none')
  }

  showSuccessMessage() {
    this.successMessageTarget.classList.remove('d-none');
  }

  showErrorMessage() {
    this.errorMessageTarget.classList.remove('d-none');
  }
}
