import { Controller } from "@hotwired/stimulus";

window.global ||= {};

export default class extends Controller {
  static targets = [ "role"];

  connect() {
    this.disableOrganizationSelects(this.roleTarget.value);
    this.toggleAssociatedCommission(this.roleTarget.value);
  }

  onRoleChange() {
    this.disableOrganizationSelects(this.roleTarget.value);
    this.toggleAssociatedCommission(this.roleTarget.value);
  }

  disableOrganizationSelects(role) {
    const acSelect    = document.querySelector('select#ac_organization_id');
    const sbSelect    = document.querySelector('select#sb_organization_id');
    const promoSelect = document.querySelector('select#promo_organization_id');
    const acContainer    = document.querySelector('.ac-organization');
    const sbContainer    = document.querySelector('.sb-organization');
    const promoContainer = document.querySelector('.promo-organization');
    const hiddenInput = document.querySelector('input#user_organization_id');
  
    if (role == "athletic_commission_member" || role == "athletic_commission_viewer") {
      this.showSelect(acSelect, acContainer);
      this.hideSelect(sbSelect, sbContainer);
      this.hideSelect(promoSelect, promoContainer);
      hiddenInput.disabled = true
    }
  
    if (role == "sanctioning_body_member") {
      this.showSelect(sbSelect, sbContainer);
      this.hideSelect(acSelect, acContainer);
      this.hideSelect(promoSelect, promoContainer);
      hiddenInput.disabled = true
    }
  
    if (role == "promoter" || role == "promoter_viewer" || role == "promoter_assistant") {
      this.showSelect(promoSelect, promoContainer);
      this.hideSelect(acSelect, acContainer);
      this.hideSelect(sbSelect, sbContainer);
      hiddenInput.disabled = true
    }
  
    if (this.roleWithoutOrganization(role)) {
      this.hideSelect(acSelect, acContainer);
      this.hideSelect(sbSelect, sbContainer);
      this.hideSelect(promoSelect, promoContainer);
  
      // For superadmins, submit { organization_id: null }
      hiddenInput.disabled = false;
      hiddenInput.value = null;
    }
  };

  toggleAssociatedCommission(role) {
    const associatedCommissionSelect = document.querySelector('select#user_associated_commission_id');
    const associatedCommissionContainer = document.querySelector('.associated-commission');

    if (role == "athlete" || role == "official") {
      this.showSelect(associatedCommissionSelect, associatedCommissionContainer);
    } else {
      this.hideSelect(associatedCommissionSelect, associatedCommissionContainer);
    }
  }


  roleWithoutOrganization(role) {
    return role == "" || ["superadmin", "admin", "data_entry", "press", "athlete", "official"].includes(role);
  }
  
  hideSelect(select, container) {
    select.value = null;
    select.disabled = true;
    global.$(select).selectpicker('refresh');
    container.style.display = 'none';
  }
  
  showSelect(select, container) {
    select.disabled = false;
    global.$(select).selectpicker('refresh');
    container.style.display = 'block';
  }
}
