import { Controller } from '@hotwired/stimulus';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default class extends Controller {
  static targets = ["hiddenInput"]

  connect() {
    this.croppers = []
  }

  submit(event) {
    event.preventDefault();

    this.croppers.forEach(({ element, cropper }) => {
      let hiddenInput = this._withinSameContainer(element, '.input-cropped-image');
      let canvas = cropper.getCroppedCanvas();
      hiddenInput.value = canvas.toDataURL("image/jpeg");  // Base64 encoding
    })

    this.element.submit();
  }

  onFileSelected(e) {
    const files = e.target.files;
    if (!files || files.length === 0) { return; }

    const aspectRatio = this._parseRational(e.target.dataset.cropperAspectRatio);
    const file = files[0];
    if (URL) {
      this._onFileSelected(e.target, URL.createObjectURL(file), aspectRatio);
    } else if (FileReader) {
      const reader = new FileReader();
      reader.onload = _ => this.onFileSelected(e.target, reader.result);
      reader.readAsDataURL(file);
    }
  }

  _parseRational(rationalStr) {
    if (rationalStr === undefined) {
      console.log("No aspect ratio provided, defaulting to 1:1");
      return 1;
    }
    const [numenator, denominator] = rationalStr.split('/').map (x => parseInt(x, 10));
    return numenator / denominator;
  }

  _onFileSelected(element, url, aspectRatio) {
    let image = this._withinSameContainer(element, '.cropper-image');
    let preview = this._withinSameContainer(element, '.cropper-preview');

    image.src = url;
    preview.style.height = "200px";

    this.croppers.push(
      {
        element: element,
        cropper: new Cropper(image, {
          viewMode: 1,
          aspectRatio: aspectRatio,
          autoCropArea: 1,  // non-cropped image by default, can be reduced to e.g. 0.8
          preview: preview,
          background: false
        })
      }
    )
  }

  _withinSameContainer(element, selector) {
    const container = element.closest('.cropper-main');
    return container.querySelector(selector);
  }
}
