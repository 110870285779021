document.addEventListener('turbo:load', () => {
  const form = document.querySelector('form.suspension');
  if (!form) { return; }

  const radioButtons   = form.querySelectorAll("input[name='suspension[indefinite]']");
  const startDateInput = form.querySelector('input#suspension_start_date');
  const endDateInput   = form.querySelector('input#suspension_end_date');
  const lengthInput    = form.querySelector('input#suspension_length');

  const pickerOptions = { dateFormat: "m/d/Y", allowInput: true };
  const startDatePicker = flatpickr(startDateInput, pickerOptions);
  const endDatePicker   = flatpickr(endDateInput,   pickerOptions);

  for (let i = 0; i < radioButtons.length; i++) {
    radioButtons[i].addEventListener('change', (event) => {
      clearEndDate(event.target.value, lengthInput, endDateInput);
    });
  }

  lengthInput.addEventListener('input', (event) => {
    setEndDate(event.target.value, startDatePicker, endDatePicker);
  });

  startDateInput.addEventListener('input', (event) => {
    setLength(lengthInput, startDatePicker, endDatePicker);
  });

  endDateInput.addEventListener('input', (event) => {
    setLength(lengthInput, startDatePicker, endDatePicker);
  });
})

const clearEndDate = (lengthIndefinite, lengthInput, endDateInput) => {
  if (lengthIndefinite == "true") {
    lengthInput.disabled = true;
    lengthInput.value = null;
    endDateInput.disabled = true;
    endDateInput.value = null;
  }
  else {
    lengthInput.disabled  = false;
    endDateInput.disabled = false;
  }
}

const setEndDate = (value, startDatePicker, endDatePicker) => {
  const length = parseInt(value);
  if (!length || length <= 0) { return; }

  const startDate = startDatePicker.selectedDates[0];
  if (!startDate) { return; }

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + length);
  endDatePicker.setDate(endDate);
}

const setLength = (lengthInput, startDatePicker, endDatePicker) => {
  const startDate = startDatePicker.selectedDates[0];
  const endDate   =   endDatePicker.selectedDates[0];

  if (!startDate || !endDate || lengthInput.disabled) {
    lengthInput.value = null;
    return;
  }

  const oneDay = 24 * 60 * 60 * 1000;
  lengthInput.value = Math.round((endDate - startDate) / oneDay);
}
